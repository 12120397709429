<template>
  <div class="account innerpage">
    <HeaderInnerpage></HeaderInnerpage>

    <v-container class="mt-6 innerpage-container">
      <v-row>
        <v-col cols="12">
          <h3 class="h3">My Account</h3>
        </v-col>
      </v-row>

      <template v-if="!isLoading">
        <v-row>
          <v-col cols="9">
            <v-alert v-if="apiError" type="error">{{ apiError }}</v-alert>
            <v-card-title class="px-0">
              <h4 class="h4">My Profile</h4>
            </v-card-title>
            <v-card class="mb-4">
              <v-card-title class="headline">
                {{ currentUser.user.organizations.company }}
              </v-card-title>
              <v-card-text v-if="!isUserReviewer && !isAdminEditor">
                Owner of licenses:
                {{ currentUser.user.organizations.first_name }}
                {{ currentUser.user.organizations.last_name }} -
                {{ currentUser.user.organizations.email }}
              </v-card-text>
              <v-card-text v-if="isUserReviewer">
                <v-alert outlined type="success" text>
                  UID: {{ currentUser.user.uid }}
                </v-alert>
              </v-card-text>
            </v-card>

            <v-simple-table dense class="elevation-5 align-center">
              <template>
                <thead>
                  <tr>
                    <th class="text-left">ID</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ currentUser.user.id }}</td>
                    <td>{{ currentUser.user.name }}</td>
                    <td>{{ currentUser.user.email }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <template v-if="isUserFull">
          <v-row>
            <v-col cols="12">
              <v-card-title class="px-0">
                <h4 class="h4">My Organization</h4>
              </v-card-title>

              <v-data-table
                :headers="headers"
                :items="organization"
                class="elevation-5 align-center"
                hide-default-footer
                disable-filtering
                disable-sort
              >
                <template v-slot:top>
                  <v-dialog v-model="dialog" max-width="500px">
                    <v-form :disabled="isProfileLocked">
                      <v-card>
                        <v-card-title>
                          <h3 class="text-h4">{{ formTitle }}</h3>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-alert v-if="isProfileLocked" type="warning">
                              Profile Locked, please contact us to make updates.
                            </v-alert>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  filled
                                  v-model="editedItem.company"
                                  label="Company Name"
                                ></v-text-field>
                                <v-text-field
                                  filled
                                  v-model="editedItem.first_name"
                                  label="First Name"
                                ></v-text-field>
                                <v-text-field
                                  filled
                                  v-model="editedItem.last_name"
                                  label="Last Name"
                                ></v-text-field>
                                <v-text-field
                                  filled
                                  v-model="editedItem.email"
                                  label="Email"
                                ></v-text-field>
                                <v-text-field
                                  filled
                                  v-model="editedItem.phone"
                                  label="Phone"
                                ></v-text-field>
                                <v-text-field
                                  filled
                                  v-model="editedItem.phone_ext"
                                  label="Phone Extension"
                                ></v-text-field>
                                <v-text-field
                                  filled
                                  v-model="editedItem.main_address"
                                  label="Address"
                                ></v-text-field>
                                <v-text-field
                                  filled
                                  v-model="editedItem.city"
                                  label="City"
                                ></v-text-field>
                                <v-text-field
                                  filled
                                  v-model="editedItem.state"
                                  label="State"
                                ></v-text-field>
                                <v-text-field
                                  filled
                                  v-model="editedItem.zip"
                                  label="Zip"
                                ></v-text-field>
                                <v-text-field
                                  filled
                                  v-model="editedItem.country"
                                  label="Country"
                                ></v-text-field>
                                <v-text-field
                                  filled
                                  v-model="editedItem.website"
                                  label="Website"
                                ></v-text-field>
                                <v-file-input
                                  filled
                                  v-model="editedItem.logo"
                                  accept="image/*"
                                  label="Upload Logo"
                                ></v-file-input>
                                <!-- <v-text-field
                                filled
                                v-model="editedItem.license_type"
                                label="Licence Type"
                              ></v-text-field>-->
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" text @click="close"
                            >Cancel</v-btn
                          >
                          <v-btn color="primary" text @click="save(editedItem)"
                            >Save Organization</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-icon class="mr-2" @click="editItem(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
              </v-data-table>

              <v-snackbar v-model="snackbar" right top>
                {{ alert }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >Close</v-btn
                  >
                </template>
              </v-snackbar>
            </v-col>
          </v-row>
        </template>

        <template v-if="isUserFull">
          <v-row>
            <v-col cols="12">
              <v-card-title class="px-0">
                <h4 class="h4">My Locations</h4>
              </v-card-title>

              <v-data-table
                :headers="headers_location"
                :items="my_locations"
                class="elevation-5 align-center"
                hide-default-footer
                disable-filtering
                disable-sort
              >
                <template slot="no-data">
                  No location selected, change location &nbsp;
                  <v-icon class="mr-2" @click="modalLocation = true"
                    >mdi-plus</v-icon
                  >
                </template>

                <template v-slot:top>
                  <v-dialog v-model="modalLocation" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <h3 class="text-h4">Edit Location</h3>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-select
                                v-model="location"
                                :items="locations"
                                item-text="name"
                                :menu-props="{ maxHeight: '400' }"
                                label="Select Location"
                                return-object
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="modalLocation = false"
                          >Cancel</v-btn
                        >
                        <v-btn color="primary" text @click="selectLocationModal"
                          >Save Location</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>

                <template v-slot:item.actions>
                  <v-icon class="mr-2" @click="modalLocation = true"
                    >mdi-pencil</v-icon
                  >
                </template>
              </v-data-table>

              <v-snackbar v-model="snackbar" right top>
                {{ alert }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >Close</v-btn
                  >
                </template>
              </v-snackbar>
            </v-col>
          </v-row>
        </template>

        <template v-if="isUserFull">
          <v-row>
            <v-col cols="12">
              <v-card-title class="px-0">
                <div class="d-flex align-center">
                  <h4 class="h4">My Worker Groups</h4>
                  <v-btn
                    @click="modalworkerGroups = true"
                    rounded
                    color="secondary"
                    class="ml-3"
                    >Add
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-card-title>

              <v-data-table
                :loading="dataLoading"
                :headers="headers_worker_group"
                :items="workerGroupsSelected"
                class="elevation-5 align-center"
                item-key="id"
                :disable-sort="true"
                :options.sync="options"
                :server-items-length="itemsTotal"
                multi-sort
                :footer-props="{
                  itemsPerPageOptions: [10]
                }"
              >
                <template slot="no-data">
                  No Worker Group selected, add a worker group &nbsp;
                  <v-icon class="mr-2" @click="modalworkerGroups = true"
                    >mdi-plus</v-icon
                  >
                </template>

                <template v-slot:top>
                  <v-dialog v-model="modalworkerGroups" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <h3 class="text-h4">Select a worker group to add</h3>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-select
                                v-model="workerGroup"
                                :items="workerGroupsList"
                                item-text="name"
                                :menu-props="{ maxHeight: '400' }"
                                label="Select a Worker Group"
                                return-object
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="modalworkerGroups = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="primary"
                          text
                          @click="selectWorkerGroupModal"
                          >Save Organization</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>

                <template v-slot:item.actions="{ item }">
                  <!--                  <v-icon class="mr-2" @click="modalworkerGroups = true">mdi-plus</v-icon>-->
                  <v-icon class="mr-2" @click="deleteWorkerGroup(item)"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>

              <v-snackbar v-model="snackbar" right top>
                {{ alert }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >Close</v-btn
                  >
                </template>
              </v-snackbar>
            </v-col>
          </v-row>
        </template>
      </template>

      <template v-else>
        <!-- loading -->
        <v-col cols="12">
          <p class="text-center text-overline mb-8">Loading new data</p>
          <v-progress-linear
            color="secondary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </template>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import HeaderInnerpage from "@/components/HeaderInnerpage";
import OrgService from "@/services/org.service";
import { Role } from "@/helpers/roles";
import WorkgroupService from "@/services/workergroup.service";
import LocationService from "@/services/location.service";
import teamlocationService from "@/services/teamlocation.service";
import teamworkergroupService from "@/services/teamworkergroup.service";
// import ReportService from "@/services/report.service";

// import { mapMutations } from 'vuex';

export default {
  name: "Profile",
  components: {
    HeaderInnerpage
  },
  data: () => ({
    dataLoading: true,
    isLoading: true,
    snackbar: false,
    alert: "",
    apiError: "",
    dialog: false,
    headers: [
      {
        text: "Company Name",
        align: "start",
        value: "company",
        width: "33.3%"
      },
      {
        text: "License Type",
        align: "start",
        value: "license_type",
        width: "33.3%"
      },
      { text: "Actions", value: "actions", sortable: false, width: "10%" }
    ],
    organization: [],
    isProfileLocked: false,
    editedIndex: -1,
    editedItem: {
      company: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      phone_ext: "",
      main_address: "",
      city: "",
      state: "",
      logo: null,
      zip: "",
      country: "",
      website: ""
    },
    defaultItem: {
      company: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      phone_ext: "",
      main_address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      website: ""
    },
    lastPage: null,
    options: {},
    itemsTotal: 10,

    //locations
    location: {},
    locations: [],
    my_locations: [],
    modalLocation: false,
    headers_location: [
      { text: "Name", align: "start", value: "name", width: "33.3%" },
      { text: "Address", align: "start", value: "address", width: "33.3%" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" }
    ],

    workerGroup: {},
    workerGroupsSelected: [],
    workerGroupsList: [],
    modalworkerGroups: false,
    headers_worker_group: [
      { text: "Name", align: "start", value: "name", width: "66.6%" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" }
    ]
  }),
  computed: {
    isUserFull() {
      let userFull = false;

      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserFull) {
          userFull = true;
        }
      });
      return userFull;
    },
    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },
    isUserReviewer() {
      let userReviewer = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserReviewer1) {
          userReviewer = true;
        }
      });
      return userReviewer;
    },
    formTitle() {
      return "Edit Organization";
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  watch: {
    options: {
      async handler() {
        if (this.lastPage !== null) {
          this.getPagedWorkergroups();
        }
      },
      deep: true
    },

    dialog(val) {
      val || this.close();
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.initialize();
  },

  mounted() {},

  methods: {
    async getPagedWorkergroups() {
      this.dataLoading = true;
      const { page } = this.options;
      const userWorkergroupsSelected = await teamworkergroupService.getPaginated(
        {
          user_id: this.currentUser.user.id,
          query: {
            page: page
          }
        }
      );
      this.workerGroupsSelected = userWorkergroupsSelected.data.data;
      this.options.itemsPerPage = parseInt(
        userWorkergroupsSelected.data.per_page
      );
      this.itemsTotal = userWorkergroupsSelected.data.total;
      this.lastPage = userWorkergroupsSelected.data.last_page;
      this.$store.commit("auth/updateWorkerGroup", this.workerGroupsSelected);
      this.dataLoading = false;
    },

    async serviceDeleteWorkerGroup(id) {
      await teamworkergroupService.remove({
        user_id: this.currentUser.user.id,
        workergroup_id: id
      });
      this.getPagedWorkergroups();
      this.dataLoading = false;
      this.showSnackbar("Worker Groups have been updated");
    },

    async saveWorkerGroup(workerGroupList) {
      this.dataLoading = true;
      await teamworkergroupService.create(workerGroupList);
      this.showSnackbar("Worker Groups have been updated");
      this.getPagedWorkergroups();
      this.dataLoading = false;
      this.showSnackbar("Worker Groups have been updated");
    },

    async saveNewLocation(newLocation) {
      await teamlocationService.create(newLocation);
      this.$store.commit("auth/updateLocationUser", this.my_locations[0]);
      this.showSnackbar("Locations have been updated");
    },

    deleteWorkerGroup(item) {
      this.dataLoading = true;
      this.serviceDeleteWorkerGroup(item.id);
    },

    selectWorkerGroupModal() {
      this.isLoading = true;
      this.saveWorkerGroup({
        query: {
          user_id: this.currentUser.user.id,
          workergroup_id: this.workerGroup.id
        }
      });
      this.getPagedWorkergroups();
      this.isLoading = false;
      this.modalworkerGroups = false;
    },

    selectLocationModal() {
      this.isLoading = true;
      this.my_locations.length = 0;
      this.my_locations.push(this.location);
      this.saveNewLocation({
        query: {
          user_id: this.currentUser.user.id,
          location_id: this.my_locations[0].id
        }
      });
      this.isLoading = false;
      this.modalLocation = false;
    },

    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },

    async initialize() {
      this.getPagedWorkergroups();

      const response = await OrgService.find({});

      const myOrganization = response.data.data.organization;

      //disable the Organization Form if the profile is locked
      if (this.currentUser.user.user_organization.organization)
        this.isProfileLocked = this.currentUser.user.user_organization.organization.profile_locked;

      this.organization.push(myOrganization);

      const myLocation = await LocationService.find({
        query: {
          page: 0
        }
      });

      this.locations = myLocation.data.data;
      if (this.locations.length) {
        this.location = this.locations[0];
      }

      if (
        this.currentUser.user.locations &&
        Object.keys(this.currentUser.user.locations).length
      ) {
        this.my_locations.push(this.currentUser.user.locations);
      }

      // if(this.currentUser.user.workergroups && Object.keys(this.currentUser.user.workergroups).length){
      //   this.workerGroupsSelected = this.currentUser.user.workergroups;
      // }

      // const userWorkergroupsSelected = await teamworkergroupService.getPaginated({
      //   user_id: this.currentUser.user.id
      // });
      //
      // this.workerGroupsSelected = userWorkergroupsSelected.data.data;

      const responseWorkgroup = await WorkgroupService.findNoPaginated({
        query: {
          page: 0
        }
      });

      this.workerGroupsList = responseWorkgroup.data.data;

      // const responseWorkgroup = await teamworkergroupService.get({
      //       user_id: this.currentUser.user.id
      // });
      //
      // this.workerGroupsList = responseWorkgroup.data;

      // this.workerGroupsList.forEach((e, eindex) => {
      //   this.workerGroupsSelected.forEach((i) => {
      //     if(e.id === i.id){
      //       this.workerGroupsList.splice(eindex, 1);
      //     }
      //   })
      // })
      //
      if (this.workerGroupsList.length) {
        this.workerGroup = this.workerGroupsList[0];
      }

      this.isLoading = false;
    },

    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    editItem(item) {
      this.editedIndex = this.organization.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save(newInformation) {
      if (this.editedIndex > -1) {
        // update item
        let data = new FormData();
        data.append("company", newInformation.company);
        data.append("first_name", newInformation.first_name);
        data.append("last_name", newInformation.last_name);
        data.append("email", newInformation.email);
        data.append("phone", newInformation.phone);
        data.append("phone_ext", newInformation.phone_ext);
        data.append("main_address", newInformation.main_address);
        data.append("city", newInformation.city);
        data.append("logo", newInformation.logo);
        data.append("state", newInformation.state);
        data.append("zip", newInformation.zip);
        data.append("country", newInformation.country);
        data.append("website", newInformation.website);

        console.log("data", data);

        try {
          const OrgId = this.organization[this.editedIndex].id;
          await OrgService.update({
            id: OrgId,
            data: data
          });
          Object.assign(this.organization[this.editedIndex], this.editedItem);
          this.showSnackbar("Organization details have been updated");
        } catch (e) {
          this.showSnackbar(e.response.data.message);
        }
      }
      this.close();
    }

    // ...mapMutations(['auth/updateLocationUser'])
  }
};
</script>
